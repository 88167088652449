<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <div
      v-if="
        employeeFolders.employeeFoldersData != undefined &&
        employeeFolders.employeeFoldersData.length != 0
      "
    >
      <div class="row">
        <div
          v-for="(employeeFolder, index) in employeeFolders.employeeFoldersData"
          :key="index"
          class="col-md-4 col-lg-3"
        >
          <EmployeeFoldersCard
            :employeeFolderData="employeeFolder"
            v-on:setEmployeeFolderData="
              employeeFolders.employeeFolder.fillData($event)
            "
          />
        </div>
      </div>
      <EmployeeFolderInfo
        :employeeFolderData="employeeFolders.employeeFolder"
      />
      <EmployeeFolderDelete
        :employeeFolderData="employeeFolders.employeeFolder"
        v-on:refresh="getAllEmployeeFolders()"
      />

      <Pagination
        v-if="!isLoading"
        :paginationData="employeeFolders.pagination"
        v-on:changePage="changePage"
      />
    </div>

    <EmployeeFolderFloatBtns
      :theFilterData="employeeFolders.filterData"
      v-on:search="search($event)"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import EmployeeFoldersCard from "@/components/employees/employeeFolders/EmployeeFoldersCard.vue";
import EmployeeFolderInfo from "@/components/employees/employeeFolders/EmployeeFolderInfo.vue";
import EmployeeFolderDelete from "@/components/employees/employeeFolders/EmployeeFolderDelete.vue";
import EmployeeFolderFloatBtns from "@/components/employees/employeeFolders/EmployeeFolderFloatBtns.vue";
import Pagination from "@/components/general/Pagination.vue";
import createToastMixin from "@/utils/create-toast-mixin";
import EmployeeFolders from "@/models/employees/employeeFolders/EmployeeFolders";

export default {
  name: "EmployeeFolders",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    EmployeeFoldersCard,
    EmployeeFolderInfo,
    EmployeeFolderDelete,
    EmployeeFolderFloatBtns,
    Pagination,
  },
  computed: {
    ...mapGetters(["employeeToken"]),
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      employeeFolderToken: "",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken,
      language: localStorage.getItem("userLanguage") || "ar",
      employeeFolders: new EmployeeFolders(),
    };
  },
  methods: {
    changePage(page) {
      this.employeeFolders.pagination.selfPage = page;
      this.getAllEmployeeFolders();
    },
    search(data) {
      this.employeeFolders.filterData.fillData(data);
      this.employeeFolders.employeeFoldersData = [];
      this.getAllEmployeeFolders();
    },
    async getAllEmployeeFolders() {
      this.isLoading = true;
      // this.employeeFolders.filterData.employeeToken = this.employeeToken;
      try {
        const response =
          await this.employeeFolders.employeeFolder.getAllEmployeeFolders(
            this.language,
            this.userAuthorizeToken,
            this.employeeFolders.pagination,
            this.employeeFolders.filterData
          );

        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.employeeFolders.fillData(response.data);
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.employeeFolders.employeeFoldersData = [];
          this.exceptionImg = "";
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.employeeFolders.employeeFoldersData = [];
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.employeeFolders.employeeFoldersData = [];
          this.exceptionImg = "";
          this.exceptionMsg = response.data.msg;
        }
      } catch (error) {
        this.employeeFolders.employeeFoldersData = [];
        this.exceptionImg = "illustrator-somethingWrong.svg";
        this.exceptionMsg = this.$t("errorCatch");
      }
      this.isLoading = false;
    },
  },
  async created() {
    if (this.employeeToken) {
      this.employeeFolders.filterData.employeeToken = this.employeeToken;
    }
    this.getAllEmployeeFolders();
  },
};
</script>
