var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('figure',{staticClass:"card",on:{"mouseover":_vm.showAllActions,"mouseleave":_vm.hideAllActions}},[_c('router-link',{attrs:{"to":{
      name: 'EmployeesFoldersMedias',
      params: {
        employeeFolderToken: _vm.employeeFolderData.employeeFolderToken,
      },
    },"title":_vm.$t('employeeFolders.open')}},[_c('img',{staticClass:"mainImg",attrs:{"src":_vm.imageSrc,"onerror":("this.src='" + (_vm.employeeFolderData.defaultImg) + "'"),"alt":"Image"}})]),_c('footer',{directives:[{name:"show",rawName:"v-show",value:(_vm.showActions),expression:"showActions"}]},[_c('div',{staticClass:"actions"},[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.EmployeeFolderInfo",modifiers:{"EmployeeFolderInfo":true}}],staticClass:"btn p-0",attrs:{"title":_vm.$t('info')},on:{"click":function($event){return _vm.setEmployeeFolderData(_vm.employeeFolderData)}}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/info.svg")}})]),_c('router-link',{attrs:{"to":{
          name: 'EmployeeFolderEdit',
          params: {
            employeeFolderToken: _vm.employeeFolderData.employeeFolderToken,
          },
        },"title":_vm.$t('edit')}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/pencil.svg")}})]),_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.EmployeeFolderDelete",modifiers:{"EmployeeFolderDelete":true}}],staticClass:"btn p-0",attrs:{"title":_vm.$t('delete')},on:{"click":function($event){return _vm.setEmployeeFolderData(_vm.employeeFolderData)}}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/trash.svg")}})])],1)]),_c('figcaption',[_c('h3',{staticClass:"cut-2line"},[_vm._v(" "+_vm._s(_vm.employeeFolderData.employeeFolderNameCurrent)+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }